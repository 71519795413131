import React from "react";
import Tile from "../Tile";
import { AmpContext } from "../../utils/ampContext";
import {
  Article,
  Link,
  ThumbnailMobile,
  Thumbnail,
  NoThumbnail,
  Content,
  Tag,
  Title,
  Excerpt,
  Footer,
  Author,
  Avatar,
  TimeToRead,
  Date,
  Details,
} from "./styles";
import dayjs from "dayjs";

export default function CardPost({
  size,
  title,
  tag,
  slug,
  excerpt,
  image,
  author,
  timeToRead,
  dateCreated,
}) {
  const { isAmp } = React.useContext(AmpContext);
  const url = "/" + slug + (isAmp ? "/amp/" : "");

  return (
    <Tile>
      <Article>
        <Link to={url} title={title} size={size}>
          {image ? (
            isAmp ? (
              <ThumbnailMobile large={size === "large"}>
                <amp-img
                  class="cover"
                  src={image.src}
                  srcSet={image.srcSet}
                  sizes={image.sizes}
                  alt={title}
                  layout="fill"
                />
              </ThumbnailMobile>
            ) : (
              <Thumbnail
                large={size === "large"}
                fluid={image}
                alt={title}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            )
          ) : (
            <NoThumbnail large={size === "large"} />
          )}
          <Content large={size === "large"}>
            {tag && <Tag>{tag}</Tag>}
            <Title>{title}</Title>
            <Excerpt>{excerpt}</Excerpt>
            {(author || timeToRead) && (
              <Footer>
                <Details>
                  {author && (
                    <Author>
                      {isAmp ? (
                        <amp-img
                          src={author.image.src}
                          srcSet={author.image.srcSet}
                          sizes={author.image.sizes}
                          alt={author.name}
                          width={30}
                          height={30}
                          layout="fixed"
                        />
                      ) : (
                        <Avatar
                          fixed={author.image}
                          objectFit="cover"
                          objectPosition="50% 50%"
                          alt={author.name}
                        />
                      )}
                    </Author>
                  )}
                  <Date>{dayjs(dateCreated).format("DD MMM YYYY")}</Date>
                </Details>
                {timeToRead && <TimeToRead>{timeToRead} min read</TimeToRead>}
              </Footer>
            )}
          </Content>
        </Link>
      </Article>
    </Tile>
  );
}
